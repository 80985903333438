.searchImage {
    margin-left: 10px!important;
    height: 35px;
    width: 35px; }

.searchLabel {
    margin-top: auto!important;
    margin-left: 10px!important;
    margin-bottom: auto!important; }

.prompt {
    border-radius: 0.4rem!important; }

.ui {
    div {
        &.input {
            max-height: 32px; } } }
