@import './BaseStyle.sass';

.productsContainer {

  @extend %pageContainer; }

.productsCatalogCol {
    max-width: 150px!important; }

.productsNameCol {
    max-width: 200px!important; }

.productsSizeCol {
    max-width: 80px!important; }

.productsSkuCol {
    max-width: 120px!important; }

.productsCategoryCol {
    max-width: 120px!important; }

.productsCityCol {
    max-width: 100px!important; }

.productsCostCol {
    max-width: 200px!important; }

.productsDescriptionCol {
    max-width: 250px!important; }

.productsMenuCol {
    max-width: 20px!important; }

.productsTableContainer {
    border: 1px solid #bcbcbc;
    border-radius: 6px;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 3px rgba(0,0,0,.2);
    @extend %horizontallyCentered; }

.productsTableTitleRow {
    @extend %horizontallyCentered;
    border-bottom: 1px solid #bcbcbc;
    padding-bottom: 10px; }

.productsTableRow {
    @extend %horizontallyCentered;
    border-bottom: 1px solid #bcbcbc;
    padding-top: 5px;
    padding-bottom: 7px; }

.productsScroll {
    height: 65vh!important; }

.productsTableDropDown {
    min-width: 70px;
    a {
        svg {
            display: block; }
        &.dropdown-toggle:after {
            display: none!important; }

        &.dropdown-toggle {
            padding-left: 0 !important;
            padding-right: 0 !important;
            max-height: 14px!important; } } }

.productsTab {
    div {
        &.menu {
            margin-top: 20px!important;
            margin-bottom: 10px!important;
            margin-left: auto!important;
            margin-right: auto!important;
            width: 480px;
            a {
                &.active {
                    color: $primary-light-color!important;
                    border-color: $primary-light-color!important; } } }
        &.tab {
            padding: 0 !important;
            border: 0;
            box-shadow: 0 2px 3px rgba(0,0,0,0);
            margin-top: 5px!important; } } }
