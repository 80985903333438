@import './BaseStyle.sass';

.clientsContainer {
  @extend %pageContainer; }

.clientsTableContainer {
    border: 1px solid #bcbcbc;
    border-radius: 6px;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 3px rgba(0,0,0,.2);
    @extend %horizontallyCentered; }

.clientsTableTitleRow {
    @extend %horizontallyCentered;
    border-bottom: 1px solid #bcbcbc;
    padding-bottom: 10px; }

.clientsTableRow {
    @extend %horizontallyCentered;
    border-bottom: 1px solid #bcbcbc;
    padding-top: 5px;
    padding-bottom: 7px; }

.clientsNameCol {
    max-width: 200px!important;
    min-width: 200px!important; }
.clientsUserCountCol {
    max-width: 80px!important;
    min-width: 80px!important; }
.clientsCatalogsCol {
    max-width: 100px!important;
    min-width: 100px!important; }
.clientsAdminsCol {
    max-width: 150px!important;
    min-width: 150px!important; }
.clientsInvoicesCol {
    max-width: 120px!important;
    min-width: 120px!important; }
.clientsContractEndCol {
    max-width: 100px!important;
    min-width: 100px!important; }
.clientsContractStatusCol {
    max-width: 130px!important;
    min-width: 130px!important; }
.clientsDomainCol {
    max-width: 100px!important;
    min-width: 100px!important; }
.clientsMenuCol {
    max-width: 20px!important; }

.clientsScroll {
    height: 65vh!important; }
