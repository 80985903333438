@import './BaseStyle.sass';

.formContainer {
    @extend %cardContainer;
    @extend %horizontallyCentered;
    @extend %verticallyCentered;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    max-width: 600px!important;
    min-height: 350px!important;
    margin-top: 75px!important; }

.form {
    width: 350px;
    padding: 25px; }

.loginButton {
    @extend %secondaryLightButton;
    @extend %horizontallyCentered;
    width: 100px!important;
    display: block!important;
    margin-top: 10px!important;
    margin-bottom: 0px!important; }

.loginLogo {
    width: 250px!important; }

.form .form-control {
    margin-bottom: 12px; }

