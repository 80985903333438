@import './BaseStyle.sass';

.editUserAdminCloseButton {
    color: black!important;
    margin-left: auto!important;
    margin-top: 20px!important;
    margin-right: 25px!important;
    font-weight: 700;
    font-size: 21px; }

.editUserAdminModalShowButton {
    width: 35px!important;
    min-width: 35px!important; }

.editUserModalRightCol {
    max-width: 383px!important;
    min-width: 383px!important; }

.editUserModalLeftCol {
    max-width: 105px!important;
    min-width: 105px!important; }

.editUserModalButtonColumn {
    margin-left: auto;
    margin-right: 25px!important;
    max-width: 132px!important; }

.editUserModalCancelButton {
    @extend %secondaryLightButton; }

.editUserModalSaveButton {
    @extend %secondaryLightButton; }
