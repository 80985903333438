@import './BaseStyle.sass';

.newCatalogModalContainer {
    padding: 25px!important; }

.newCatalogModalPriceCol {
    max-width: 100px!important;
    min-width: 100px!important; }

.newCatalogModalCategoryCol {
    max-width: 120px!important;
    min-width: 120px!important; }

.newCatalogModalNameCol {
    max-width: 170px!important;
    min-width: 170px!important; }

.newCatalogModalDeleteCol {
    max-width: 30px!important; }

.newCatalogModalTableTitleRow {
    @extend %horizontallyCentered;
    border-bottom: 1px solid #bcbcbc;
    padding-bottom: 10px; }

.newCatalogModalTableRow {
    @extend %horizontallyCentered;
    border-bottom: 1px solid #bcbcbc;
    padding-top: 5px;
    padding-bottom: 7px; }

.newCatalogModalShow {
    max-width: 130px; }
