@import './BaseStyle.sass';

.mainNavContainer {
  @extend %pageNavContainer; }

.navBar {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-height: 53px!important; }

.navIcon {
  margin-left: auto!important;
  margin-right: auto!important; }

.navbar-brand {
  img {
    width: 40px!important;
    height: 40px!important; } }

.navProfileImage {
  width: 45px;
  height: 45px;
  object-fit: cover; }

.navbar-brand {
  margin-top: auto!important;
  margin-bottom: auto!important; }

.navSearch {
  margin-top: auto!important;
  margin-bottom: auto!important; }

.dropdown-menu {
  a {
    &.nav-link {
      height: 33px;
      padding-bottom: 7px!important;
      margin-left: 5px!important;
      margin-right: 5px!important; } }

  span {
    height: 33px;
    padding: 7px!important;
    margin-left: 5px!important;
    margin-right: 5px!important; } }


.nav-link {
  height: 57px;
  padding-bottom: 0px!important;
  padding-top: 7px;
  margin-left: 5px!important;
  margin-right: 5px!important; }

.notificationBubble {
    position: absolute;
    min-width: 17px;
    top: -7px;
    text-align: center;
    right: 15px;
    padding: 1px 2px;
    border-radius: 50%;
    font-size: 12px;
    background-color: $primary-warm-color;
    color: white;
    font-weight: bold;
    line-height: 16px;
    height: 17px; }

.navIconRow {
  margin-top: 4px;
  margin-bottom: {} }

.navbar-nav {
  button {
      &:focus {
          outline: none!important;
          box-shadow: none !important; } } }
.nav-item {

  a {
    &.active {
      color: $primary-light-color!important;
      border-bottom: 3px solid $primary-light-color!important;
      svg {
        &.navIcon {
          color: $primary-light-color!important; } } } } }

.verticalSeparator {
  background-color: rgb(188, 188, 188);
  width: 1px;
  max-width: 1px;
  height: 100%;
  margin-left: 5px!important;
  margin-right: 5px!important; }

.button {
    &:focus {
        outline: none!important;
        box-shadow: none !important; } }
