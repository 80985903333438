@import './BaseStyle.sass';

.catalogNameCol {
    max-width: 80px!important; }

.catalogCountCol {
    max-width: 120px!important; }

.catalogCategoriesCol {
    max-width: 300px!important; }

.catalogMenuCol {
    max-width: 20px!important; }

.catalogTableContainer {
    border: 1px solid #bcbcbc;
    border-radius: 6px;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 3px rgba(0,0,0,.2);
    @extend %horizontallyCentered; }

.catalogContainer {
    max-width: 650px!important;
    @extend %horizontallyCentered; }
