@import './BaseStyle.sass';

.usersContainer {
  @extend %pageContainer; }

.usersTableContainer {
    border: 1px solid #bcbcbc;
    border-radius: 6px;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 3px rgba(0,0,0,.2);
    @extend %horizontallyCentered; }

.usersTableTitleRow {
    @extend %horizontallyCentered;
    border-bottom: 1px solid #bcbcbc;
    padding-bottom: 10px; }

.usersNameCol {
    max-width: 200px!important;
    min-width: 200px!important; }

.usersEmailCol {
    max-width: 825px!important;
    min-width: 825px!important; }

.usersEmailColE {
    max-width: 825px!important;
    min-width: 825px!important;
    overflow: hidden !important;
    white-space: normal !important;
    word-wrap: break-word !important; }

.usersAdminCol {
    max-width: 75px!important;
    min-width: 75px!important; }

.usersActiveCol {
    max-width: 75px!important;
    min-width: 75px!important; }

.usersTableRow {
    @extend %horizontallyCentered;
    border-bottom: 1px solid #bcbcbc;
    padding-top: 5px;
    padding-bottom: 7px; }

.usersMenuCol {
    max-width: 20px!important; }
