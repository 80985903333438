@import './BaseStyle.sass';

.categoriesContainer {
    max-width: 650px!important;
    @extend %horizontallyCentered; }

.categoriesTableContainer {
    border: 1px solid #bcbcbc;
    border-radius: 6px;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 3px rgba(0,0,0,.2);
    @extend %horizontallyCentered; }

.categoriesIDCol {
    max-width: 50px!important;
    min-width: 50px!important; }

.categoriesNameCol {
    max-width: 150px!important;
    min-width: 150px!important; }

.categoriesMenuCol {
    max-width: 20px!important;
    min-width: 20px!important; }
